<template>
  <div
    ref="gallery-player"
    :class="{ 'rounded': rounded, 'clickable': !disabled }"
    class="gallery-player"
    :style="{ 'min-height': minHeight, 'width': width }"
  >
    <v-expand-transition>
      <div
        class="player-container"
      >
        <v-responsive
          v-if="type==1"
          :aspect-ratio="square ? 1/1 : undefined"
          class="video-container"
        >
          <video 
            v-if="inView"
            ref="video"
            :src="url"
            :autoplay="autoplay"
            muted="muted"
            loop
            playsinline
            :class="{ 'rounded': rounded }"
            class="video"
            :style="{ 'max-height': square ? size+'px' : maxHeight }"
            @loadstart="loading = true;"
            @load="onLoad"
            @playing="onLoad"
          />
          <v-row
            v-show="loading"
            class="video-loading fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary lighten-3"
            />
          </v-row>
        </v-responsive>
        <v-img
          v-else-if="inView"
          ref="image"
          :aspect-ratio="square ? 1/1 : undefined"
          :max-height="maxHeight"
          :src="url"
          eager
          :class="{ 'rounded': rounded }"
          class="image"
          @load="onLoad"
          @error="handleException"
        >
          <template v-slot:placeholder>
            <v-row
              v-if="url!=null"
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="primary lighten-3"
              />
            </v-row>
          </template>
        </v-img>
      </div>
    </v-expand-transition>
  </div>
</template>

<style>

.gallery-player .video-container {
  position: relative;
}
.gallery-player .video {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.gallery-player .video-loading {
  position: absolute;
  top: 0;
  width: 100%;
}

</style>

<script>
  import { mdiPause } from '@mdi/js';

  export default {
    props: {
      url: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: 'Mídia'
      },
      width: {
        type: [String, Number],
        default: '100%'
      },
      maxHeight: {
        type: [String, Number],
        default: '80vh'
      },
      minHeight: {
        type: [String, Number],
        default: undefined
      },
      square: {
        type: Boolean,
        default: true
      },
      rounded: {
        type: Boolean,
        default: false
      },
      pauseOnHover: {
        type: Boolean,
        default: true
      },
      autoplay: {
        type: Boolean,
        default: true
      },
      inView: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    
    data: () => ({
      icons: {
        pause: mdiPause
      },
      controls: {
        toggle: false,
        playing: false,
        remove: {
          pressed: false,
          disabled: false,
          loading: false,
        }
      },
      ready: false,
      loading: false,
    }),

    computed: {
      size () {
        const loading = this.loading;
        const player = _.has(this.$refs, 'gallery-player') ? this.$refs['gallery-player'] : null;
        const width = loading ? 0 : _.isNil(player) ? this.width : player.clientWidth;
        console.log('width', width, 'minHeight', this.minHeight);
        return width;
      },
      type () {
        const url = this.url;
        return _.isNil(url) ? null : (/\/mp4/i).test(url) || (/\.mp4/i).test(url) ? 1 : 0;
      },
    },

    watch: {
      autoplay (b) {
        if (this.$refs.hasOwnProperty('video')&&!this.loading) {
          // console.log('autoplay', b, this.url);
          if (b) {
            this.controller.playing = true;
            this.$refs.video.play();
          }else{
            this.controller.playing = false;
            this.$refs.video.pause();
          }
        }
      },
      inView: {
        immediate: true,
        handler (b) {
          console.log('inView', b, this.size);
        }
      }
    },

    methods: {
      onLoad (url) {
        if (this.$refs.hasOwnProperty('video')&&this.autoplay&&!this.controls.playing) {
          this.controls.playing = true;
          this.$refs.video.play();
        }
        this.loading = false;
      },
      handleException (error) {
        console.log(error);
      },
    },

    mounted () {
      this.$nextTick(() => {
        this.ready = true;
        this.$emit('ready', true);
        console.log('media player ready');
      })
    }
  }
</script>